<template>
  <div id="app">
    <van-nav-bar title="备件查询" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-search v-model="mobileSearch" placeholder="请输入搜索关键词" @search="onSearch" />
    <div class="common-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="loadMore" offset="1" :immediate-check="false">
          <van-cell v-for="(item, index) in partsList" :key="index">
            <div @click="goUrls(`/parts/parts_detail/${item.id}`)">
              <div class="title">
                <div class="fl tit">{{ item.name }}</div>
                <div class="fr status">
                  <div v-if="item.status === 0">
                    <div class="status1"><span></span>启用</div>
                  </div>
                  <div v-if="item.status === 1">
                    <div class="status0"><span></span>停用</div>
                  </div>
                </div>
              </div>
              <div class="detail">
                <div class="info">物料号：{{ item.coding }}</div>
                <div class="info">当前数量：{{ item.number ? item.number : 0 }}</div>
                <div class="info">仓库：{{ item.store ? item.store.name : '暂无' }}</div>
                <div class="info">库位：{{ item.location_x ? item.location_x : '暂无' }}行，{{ item.location_y ? item.location_y : '暂无' }}列</div>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import API from '@/api/stock/parts'
import {Toast} from "vant";
export default {
  inject: ['goUrl'],
  data() {
    return {
      query: { page: 0, limit: 15, mobile: 1 },
      mobileSearch: '',
      partsList: [],
      loading: false,
      finished: false,
      refreshing: false,
      finishLoading: false,
      totalParts: 0
    }
  },
  created() {
    this.index()
  },
  methods: {
    index() {
      this.finishLoading = false
      this.query.page += 1
      if (this.refreshing) {
        this.partsList = []
        this.refreshing = false
      }
      return API.partsList(this.query).then(res=> {
        if (res.data.total === 0) {
          Toast('暂无备件')
          this.partsList = []
          this.totalParts = 0
        } else {
          this.totalParts = res.data.total
          this.partsList.push(...res.data.data)
          this.loading = false
        }
        this.partsList.length >= this.totalParts ? (this.finished = true) : (this.finished = false)
      }).finally(() => {
        this.finishLoading = true
      })
    },
    loadMore() {
      if (this.finishLoading === true) {
        this.index()
      }
    },
    onSearch() {
      this.query.mobileSearch = this.mobileSearch
      this.query.page = 0
      this.partsList = []
      this.index()
    },
    onRefresh() {
      this.partsList = []
      this.query.page = 0
      this.finished = true
      this.index()
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    goUrls(url) {
      this.goUrl(url)
    }
  }
}
</script>
<style scoped>
.status0{
  color: #FF6666;
}
.status0 span{
  background-color: #FF6666 !important;
}
.status1{
  color: #33CC33;
}
.status1 span{
  background-color: #33CC33 !important;
}
</style>
